import dayjs from 'dayjs';
import { TimeUnit } from '@root/types/constants';

export function getExpiryTimeInHours(referralExpiry: number | null) {
  if (!referralExpiry) {
    return { expiryTimeInHours: 0, isExpired: false };
  }

  const expiryTimeInHours = Math.max(
    0,
    dayjs(referralExpiry * TimeUnit.SecondInMs).diff(dayjs(), 'hour')
  );

  return {
    expiryTimeInHours,
    isExpired: expiryTimeInHours === TimeUnit.Zero,
  };
}
