import { initializeApp, getApps, getApp } from 'firebase/app';
import type { FirebaseApp } from 'firebase/app';
import { getPerformance } from 'firebase/performance';
import type { FirebasePerformance } from 'firebase/performance';

import { firebaseConfig } from './firebaseConfig';

let firebaseApp: FirebaseApp;
let firebasePerformance: FirebasePerformance;

export function initializeFirebaseApp() {
  if (!getApps().length) {
    firebaseApp = initializeApp(firebaseConfig);
  } else {
    firebaseApp = getApp();
  }

  if (!firebasePerformance) {
    firebasePerformance = getPerformance(firebaseApp);
  }

  return { firebaseApp, firebasePerformance };
}
