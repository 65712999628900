import { LanguageSlug } from '@root/types/constants';

import { useAppTranslation } from '@hooks/useAppTranslation';

export const useLanguageSwitcher = () => {
  const { t, currentLanguage, switchLanguage } = useAppTranslation();

  const languages = Object.values(LanguageSlug).map((lang) => ({
    lang,
    label: t(`${lang}`),
  }));

  return {
    t,
    currentLanguage,
    switchLanguage,
    languages,
  };
};
