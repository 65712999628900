import LogoColor from '@assets/LogoColor.png';
import CloseIcon from '@assets/icons/Close.svg?react';
import { useAppTranslation } from '@hooks/useAppTranslation';
import ArrowRightIcon from '@assets/icons/ArrowRight.svg?react';

interface ProfileBannerProps {
  deepLink: string;
  setIsBannerOpen: (isOpen: boolean) => void;
}

export function ProfileBanner({
  deepLink,
  setIsBannerOpen,
}: ProfileBannerProps) {
  const { t } = useAppTranslation();

  return (
    <div className="flex justify-between items-center bg-black text-white py-4 px-6 lg:hidden">
      <div className="flex gap-4 items-center">
        <button onClick={() => setIsBannerOpen(false)}>
          <CloseIcon />
        </button>
        <a href={deepLink} target="_blank" rel="noopener noreferrer">
          <img
            src={LogoColor}
            className="sm:w-22.5 w-16 rounded-lg"
            alt="logo-color"
          />
        </a>
        <a
          href={deepLink}
          className="flex flex-col gap-1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="uppercase hidden sm:block sm:text-2xl md:text-3xl">
            {t('headerTitle')}
          </span>
          <span className="text-lg md:text-xl">{t('bannerSubtitle')}</span>
        </a>
      </div>
      <a
        href={deepLink}
        target="_blank"
        rel="noopener noreferrer"
        className="gap-4 border border-white rounded-full px-4 pt-2 pb-1.5 text-2xl uppercase items-center hidden sm:flex"
      >
        <span>{t('bannerOpen')}</span>
        <ArrowRightIcon className="md:block hidden" />
      </a>
      <a
        href={deepLink}
        target="_blank"
        rel="noopener noreferrer"
        className="sm:hidden block pl-4"
      >
        <ArrowRightIcon />
      </a>
    </div>
  );
}
