import { ApiRoute } from '@root/types/api';
import { getItemInfo } from '@services/api/client/getItemInfo';
import { useSuspenseQuery, useQuery } from '@tanstack/react-query';

export const useSuspendedItemInfo = (itemId: string, referralCode?: string) => {
  return useSuspenseQuery({
    queryKey: [ApiRoute.ItemInfo, itemId, referralCode],
    queryFn: () => getItemInfo(itemId, referralCode),
  });
};

export const useItemInfo = (itemId: string, referralCode?: string) => {
  return useQuery({
    queryKey: [ApiRoute.ItemInfo, itemId, referralCode],
    queryFn: () => getItemInfo(itemId, referralCode),
    enabled: !!itemId,
  });
};
