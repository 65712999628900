import { useSearch, useLocation, useNavigate } from '@tanstack/react-router';
import { useCallback, useMemo } from 'react';

import type { ModalType } from './Modal.types';

const ModalTypeKey = 'modal';

export const useModalMethods = (type?: ModalType) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const search = useSearch({ from: '__root__' }) as Record<string, unknown>;

  const isModalOpen = useMemo(() => {
    return search[ModalTypeKey] === type;
  }, [type, search]);

  const openModal = useCallback(() => {
    navigate({
      to: pathname,
      search: {
        ...search,
        [ModalTypeKey]: type,
      },
      replace: true,
    });
  }, [type, navigate, search, pathname]);

  const closeModal = useCallback(() => {
    const { [ModalTypeKey]: _, ...remainingSearch } = search;
    navigate({
      to: pathname,
      search: remainingSearch,
      replace: true,
    });
  }, [navigate, pathname, search]);

  return {
    openModal,
    closeModal,
    isModalOpen,
  };
};
