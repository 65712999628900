import { cn } from '@utils/cn';
import { ErrorCode } from '@root/types/constants';
import { useAppTranslation } from '@hooks/useAppTranslation';

export function NotFoundPage() {
  const { t } = useAppTranslation();

  const redirectUrl = import.meta.env.VITE_APP_HOME_URL;

  return (
    <section className="flex items-center flex-grow justify-center bg-white">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-black dark:text-white">
            {ErrorCode.NotFound}
          </h1>
          <p className="mb-4 text-3xl tracking-tight font-bold md:text-4xl text-black">
            {t('somethingMissing')}
          </p>
          <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">
            {t('cannotFindPage')}
          </p>
          <a
            href={redirectUrl}
            className={cn('btn-tomato', 'inline-block mt-2 py-2 text-white')}
          >
            {t('backToHomepage')}
          </a>
        </div>
      </div>
    </section>
  );
}
