import camelcaseKeys from 'camelcase-keys';
import qs from 'qs';
import snakecaseKeys from 'snakecase-keys';
import { z } from 'zod';

/**
 * Serialize request params
 * @param params - Request params
 * @returns Serialized params
 */
export function axiosRequestSerializer(params: Record<string, unknown>) {
  return qs.stringify(params, {
    sort: (a, b) => a.localeCompare(b),
    arrayFormat: 'comma',
  });
}

function validateUrl(url: string) {
  const urlValidateSchema = z.string().url();

  const validatedUrl = urlValidateSchema.safeParse(url);

  return validatedUrl.success;
}

/**
 * Compose API base URL
 * @returns API base URL
 */
export function composeApiBaseUrl() {
  try {
    const apiUrl = import.meta.env.VITE_API_URL;

    if (!apiUrl) {
      throw new Error('VITE_API_URL environment variable is not defined');
    }

    const isValidUrl = validateUrl(apiUrl);

    if (!isValidUrl) {
      throw new Error('VITE_API_URL is not a valid URL');
    }

    return apiUrl;
  } catch (error) {
    throw new Error(
      `Failed to compose API base URL: ${error instanceof Error ? error.message : 'Unknown error'}`
    );
  }
}

// Response transformation
export function transformResponse<
  T extends Record<string, unknown> | readonly Record<string, unknown>[],
>(data: T) {
  return camelcaseKeys(data, { deep: true });
}

// Request/mutation data transformation
export function transformRequest<
  T extends Record<string, unknown> | readonly Record<string, unknown>[],
>(data: T) {
  return snakecaseKeys(data, { deep: true });
}
