import { LanguageSlug } from '@root/types/constants';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook for handling translations and language switching in the app.
 *
 * @returns {Object} - The translation utilities:
 * - `t` (function): Translation function from `react-i18next` for translating keys.
 * - `switchLanguage` (function): Toggles between "de" (German) and "en" (English) languages, updating the app language and returning the new language.
 * - `currentLanguage` (string): The current language code, either "de" or "en".
 */
export const useAppTranslation = () => {
  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const switchLanguage = () => {
    const newLanguage =
      currentLanguage === LanguageSlug.DE ? LanguageSlug.EN : LanguageSlug.DE;
    i18n.changeLanguage(newLanguage);
    return newLanguage;
  };

  return {
    t,
    switchLanguage,
    currentLanguage,
  };
};
