import type { ReactNode } from 'react';

import type { Noop, PropsWithClassName } from '@root/types/common';

export enum ModalType {
  Reservation = 'reservation',
  Redirection = 'redirection',
}

interface ChildrenProps {
  openModal: Noop;
  closeModal: Noop;
  isModalOpen: boolean;
}

export type ModalQueries = (queries?: Record<string, string>) => void;

type ChildrenFunc = (props: ChildrenProps) => ReactNode;

export interface ModalProps extends PropsWithClassName {
  modalType: ModalType;
  children: ChildrenFunc | ReactNode;
  callback?: () => void;
  disableBackdropClose?: boolean;
}

export interface ModalMethods {
  isModalOpen: boolean;
  closeModal: () => void;
  openModal: (queries: ModalQueries) => void;
}
