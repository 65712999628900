import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import deCommon from './locales/de.json';
import enCommon from './locales/en.json';

// ** Consider using i18next-http-backend plugin, once the translation files size becomes more than 100kb

// ** Do not include all translations in 'common' namespace, 'common' should only keep shared translations

const resources = {
  en: {
    common: enCommon,
  },
  de: {
    common: deCommon,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    ns: ['common'],
    defaultNS: 'common',
    keySeparator: '.',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
