import { ApiRoute } from '@root/types/api';
import { api } from '@services/api';
import { AxiosResponse } from 'axios';
import { z } from 'zod';

const CreatorSchema = z.object({
  uid: z.string(),
  username: z.string(),
});

const GiftedSchema = z
  .object({
    state: z.enum(['available', 'gifted', 'reserved']),
    reserverId: z.union([z.string(), z.literal('0')]),
    reserverUsername: z.string().optional(),
    reserveTime: z.number().optional(),
    giftedTime: z.number().optional(),
  })
  .optional();

const LocationSchema = z.object({
  latitude: z.number(),
  longitude: z.number(),
});

const DeliverySchema = z.union([
  z.literal(false),
  z.object({ country: z.string(), delivery: z.boolean() }),
]);

const PickupTimeSchema = z
  .object({
    start: z.string(),
    end: z.string(),
  })
  .optional();

const DescriptionSchema = z.object({
  de: z.string(),
  en: z.string(),
});

export const ItemSchema = z.object({
  id: z.string(),
  creator: CreatorSchema,
  sharingLink: z.string().optional(),
  gifted: GiftedSchema,
  thumbUrl: z.string().optional(),
  loveCount: z.number(),
  loves: z.record(z.literal('true')).optional(),
  location: LocationSchema,
  brand: z.string(),
  size: z.string(),
  condition: z.string(),
  tags: z.array(z.string()).optional(),
  delivery: DeliverySchema,
  time: z.number(),
  pickup: z.boolean().optional(),
  private: z.boolean().optional(),
  alwaysPrivate: z.boolean().optional(),
  pickupTime: PickupTimeSchema,
  description: DescriptionSchema,
});

export const ProfileItemsResponseSchema = z.object({
  items: z.record(z.string(), ItemSchema),
});

export const ProfileItemPayloadSchema = z.object({
  username: z.string(),
  referralCode: z.string().optional(),
});

export type ProfileItem = z.infer<typeof ItemSchema>;

export type ProfileItemExtended = z.infer<typeof ItemSchema> & {
  isPrivate: boolean;
};

export type ProfileItemsResponse = z.infer<typeof ProfileItemsResponseSchema>;

export type ProfileItemsPayload = z.infer<typeof ProfileItemPayloadSchema>;

export type ItemCreator = z.infer<typeof CreatorSchema>;

export const getProfileItems = async (
  username: string,
  referralCode?: string
): Promise<ProfileItemsResponse> => {
  const response: AxiosResponse<ProfileItemsResponse, ProfileItemsPayload> =
    await api.post(ApiRoute.ProfileItems, {
      username,
      referralCode,
    });

  return response.data;
};
