import { AppEnvironment } from '@root/types/constants';
import * as Sentry from '@sentry/react';

export const initializeSentry = () => {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_APP_ENV,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    enabled:
      import.meta.env.VITE_APP_ENV === AppEnvironment.Staging ||
      import.meta.env.VITE_APP_ENV === AppEnvironment.Production,
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'https://giftd-staging.web.app',
      'https://giftd-staging.firebaseapp.com',
      'https://app.giftd.app',
      'https://giftd-9866f.firebaseapp.com',
      'https://giftd-9866f.web.app',
    ],
    replaysSessionSampleRate: 0.5,
    replaysOnErrorSampleRate: 1.0,
  });
};
