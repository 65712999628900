import { ApiRoute } from '@root/types/api';
import { api } from '@services/api';
import { AxiosResponse } from 'axios';
import { z } from 'zod';

import { ItemSchema } from './getProfileItems';

export const SingleItemResponseSchema = z.object({
  deepLink: z.string().optional(),
  item: ItemSchema,
  imageUrl: z.string(),
  profileThumbUrl: z.string().nullable(),
});

export const SingleItemPayloadSchema = z.object({
  itemId: z.string(),
  referralCode: z.string().optional(),
});

export type SingleItemResponse = z.infer<typeof SingleItemResponseSchema>;

export type SingleItemPayload = z.infer<typeof SingleItemPayloadSchema>;

export const getItemInfo = async (
  itemId: string,
  referralCode?: string
): Promise<SingleItemResponse> => {
  const response: AxiosResponse<SingleItemResponse, SingleItemPayload> =
    await api.post(ApiRoute.ItemInfo, {
      itemId,
      referralCode,
    });
  return response.data;
};
