import { LanguageSwitcher } from '@components/LanguageSwitcher/LanguageSwitcher';
import { useAppTranslation } from '@hooks/useAppTranslation';
import logo from '@assets/Logo.png';
import { ProfileBanner } from './ProfileBanner';
import { useState } from 'react';
import { useModalMethods } from '@components/Modal/Modal.hooks';
import { ModalType } from '@components/Modal/Modal.types';

interface ProfileLayoutHeaderProps {
  deepLink: string;
}

export function ProfileLayoutHeader({ deepLink }: ProfileLayoutHeaderProps) {
  const { t } = useAppTranslation();

  const [isBannerOpen, setIsBannerOpen] = useState(true);

  const { openModal } = useModalMethods(ModalType.Redirection);

  return (
    <header className="flex flex-col">
      {isBannerOpen && (
        <ProfileBanner deepLink={deepLink} setIsBannerOpen={setIsBannerOpen} />
      )}
      <section className="py-2 px-6 flex gap-2 justify-between items-center">
        <div className="flex items-center gap-4">
          <img src={logo} className="w-32" alt="Logo" />
          <LanguageSwitcher className="xs:flex hidden" />
        </div>
        <div className="flex gap-2 text-xl xs:text-2xl items-center">
          <button
            onClick={openModal}
            className="hidden sm:flex items-center uppercase py-0.5 px-3.75 rounded-full hover:text-black/80 active:scale-95 transition-all"
          >
            {t('headerSignUp')}
          </button>
          <button onClick={openModal} className="btn-tomato">
            {t('headerSignIn')}
          </button>
        </div>
      </section>
    </header>
  );
}
