import axios from 'axios';

import {
  composeApiBaseUrl,
  axiosRequestSerializer,
  transformRequest,
  transformResponse,
} from './helpers';

/**
 * Axios instance used to make requests to the API
 */
export const api = axios.create({
  baseURL: composeApiBaseUrl(),
  withCredentials: false,
  paramsSerializer: { serialize: axiosRequestSerializer },
});

api.interceptors.request.use(async (request) => {
  if (request.params) {
    request.params = transformRequest(request.params);
  }

  return request;
});

api.interceptors.response.use(async (response) => {
  if (
    response?.data &&
    response.headers['content-type']?.includes('application/json')
  ) {
    response.data = transformResponse(response.data);
  }

  return response;
});
