import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';

import '@root/styles/index.css';
import { TanStackRouterDevtools } from '@components/TantsackDevtools/TanstackRouterDevtools';
import { TanStackQueryDevtools } from '@components/TantsackDevtools/TanstackQueryDevtools';
import { QueryClient } from '@tanstack/react-query';

import { ScrollRestoration } from '@tanstack/react-router';

interface RouterContext {
  queryClient: QueryClient;
}

export const RootPage = () => {
  return (
    <div className="flex flex-col h-full min-h-screen">
      <div className="flex-grow flex flex-col">
        <Outlet />
        <ScrollRestoration getKey={({ pathname }) => pathname} />
        <TanStackRouterDevtools initialIsOpen={false} />
        <TanStackQueryDevtools initialIsOpen={false} />
      </div>
    </div>
  );
};

export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootPage,
});
