import { Outlet } from '@tanstack/react-router';
import { ProfileLayoutHeader } from './_components/ProfileLayoutHeader';
import { useAppTranslation } from '@hooks/useAppTranslation';
import { useProfileInfo } from '@hooks/queries/useProfileInfo';
import { getExpiryTimeInHours } from './_utils/getExpiryTimeInHours';
import { useItemInfo } from '@hooks/queries/useItemInfo';
import { NotFoundPage } from '@pages/-NotFound';
import { lazy, Suspense } from 'react';

const ProfileModal = lazy(() =>
  import('@features/Profile/_components/ProfileModal').then((mod) => ({
    default: mod.ProfileModal,
  }))
);

interface ProfileLayoutProps {
  profileId?: string;
  referralCode?: string;
  itemId?: string;
}

export function ProfileLayout({
  profileId,
  referralCode,
  itemId,
}: ProfileLayoutProps) {
  const { t } = useAppTranslation();

  const { data: profileData, isLoading } = useProfileInfo(
    profileId ?? '',
    referralCode
  );

  const { data: itemData, isLoading: isItemLoading } = useItemInfo(
    itemId ?? '',
    referralCode
  );

  const { expiryTimeInHours, isExpired } = getExpiryTimeInHours(
    profileData?.referralExpiry ?? 0
  );

  const deepLink = profileData?.deepLink ?? itemData?.deepLink ?? '';

  if (isLoading || isItemLoading) return null;

  if (profileId && !isLoading && !profileData) return <NotFoundPage />;

  if (itemId && !isItemLoading && !itemData) return <NotFoundPage />;

  return (
    <>
      <ProfileLayoutHeader deepLink={deepLink} />
      {!!expiryTimeInHours && referralCode && !isExpired && (
        <p className="uppercase text-center bg-pinkLight pt-1.5 pb-0.5 text-xl sm:text-2xl tracking-wide">
          {t('timeoutText', { time: expiryTimeInHours })}
        </p>
      )}
      {isExpired && (
        <p className="uppercase text-center bg-pinkLight pt-1.5 pb-0.5 text-xl sm:text-2xl tracking-wide">
          {t('referralExpired')}
        </p>
      )}
      <div className="max-w-7xl mx-auto px-6 md:mt-10 mt-6 pb-6 w-full">
        <Outlet />
        <Suspense fallback={null}>
          <ProfileModal redirectLink={deepLink} />
        </Suspense>
      </div>
    </>
  );
}
