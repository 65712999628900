export enum LanguageSlug {
  DE = 'de',
  EN = 'en',
}

export enum ProductState {
  Available = 'available',
  Reserved = 'reserved',
  Gifted = 'gifted',
}

export enum ErrorCode {
  NotFound = '404',
}

export enum AppEnvironment {
  Staging = 'staging',
  Production = 'production',
}

export enum TimeUnit {
  Zero = 0,
  SecondInMs = 1000,
  MinuteInMs = 60 * SecondInMs,
  HourInMs = 60 * MinuteInMs,
  DayInMs = 24 * HourInMs,
}
