import { ApiRoute } from '@root/types/api';
import { getProfileInfo } from '@services/api/client/getProfileInfo';
import { useSuspenseQuery, useQuery } from '@tanstack/react-query';

export const useSuspendedProfileInfo = (
  username: string,
  referralCode?: string
) => {
  return useSuspenseQuery({
    queryKey: [ApiRoute.ProfileInfo, username, referralCode],
    queryFn: () => getProfileInfo(username, referralCode),
  });
};

export const useProfileInfo = (username?: string, referralCode?: string) => {
  return useQuery({
    queryKey: [ApiRoute.ProfileInfo, username, referralCode],
    queryFn: () => getProfileInfo(username, referralCode),
    enabled: !!username,
  });
};
