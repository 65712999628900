import { LanguageSlug } from '@root/types/constants';
import { useLanguageSwitcher } from './LanguageSwitcher.hooks';
import { Switch } from '@components/ui/Switch';
import { Label } from '@components/ui/Label';
import { cn } from '@utils/cn';
interface LanguageSwitcherProps {
  className?: string;
}
export function LanguageSwitcher({ className }: LanguageSwitcherProps) {
  const { currentLanguage, switchLanguage } = useLanguageSwitcher();

  return (
    <div className={cn('flex items-center gap-2', className)}>
      <Label htmlFor="language-switch" className="uppercase text-md">
        {LanguageSlug.EN}
      </Label>
      <Switch
        id="language-switch"
        checked={currentLanguage === LanguageSlug.DE}
        onCheckedChange={switchLanguage}
      />
      <Label htmlFor="language-switch" className="uppercase text-md">
        {LanguageSlug.DE}
      </Label>
    </div>
  );
}
