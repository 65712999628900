import { ApiRoute } from '@root/types/api';
import { api } from '@services/api';
import { AxiosResponse } from 'axios';
import { z } from 'zod';

const PickupLocation = z
  .object({
    latitude: z.number(),
    longitude: z.number(),
  })
  .optional();

const PickupTime = z
  .object({
    startsAt: z.string(),
    endsAt: z.string(),
  })
  .optional();

const PublicUserInfoSchema = z.object({
  uid: z.string(),
  username: z.string(),
  bio: z.string().optional(),
  externalLinkToProfile: z.string().optional(),
  isOnline: z.boolean().optional(),
  lastTimeOnline: z.number().optional(),
  pickupTime: PickupTime,
  pickuplocation: PickupLocation,
});

const ProfileStatsSchema = z.object({
  followerCount: z.number(),
  followingCount: z.number(),
  giftedCount: z.number(),
});

export const ProfileInfoResponseSchema = z.object({
  deepLink: z.string().optional(),
  profile: PublicUserInfoSchema,
  thumbUrl: z.string().nullable(),
  profileStats: ProfileStatsSchema,
  views: z.number().optional(),
  referralExpiry: z.number().nullable(),
});

export const ProfileInfoPayloadSchema = z.object({
  username: z.string(),
  referralCode: z.string().optional(),
});

export type ProfileInfoResponse = z.infer<typeof ProfileInfoResponseSchema>;

export type ProfileInfoPayload = z.infer<typeof ProfileInfoPayloadSchema>;

export type ProfilePickupTime = z.infer<typeof PickupTime>;

export const getProfileInfo = async (
  username?: string,
  referralCode?: string
): Promise<ProfileInfoResponse> => {
  const response: AxiosResponse<ProfileInfoResponse, ProfileInfoPayload> =
    await api.post(ApiRoute.ProfileInfo, {
      username,
      referralCode,
    });

  return response.data;
};
